const OWNERS = ["spouse", "primary", "joint"] as const

export type OwnerType = typeof OWNERS[number]
export type OwnerTypeIndividual = Extract<OwnerType, "primary" | "spouse">

function isOwner(potentialOwner: string): potentialOwner is OwnerType {
    return OWNERS.includes(potentialOwner as OwnerType)
}

export function getOwnerFromString(potentialOwner: string): OwnerType {
    if (isOwner(potentialOwner)) {
        return potentialOwner;
    }

    throw new Error(`"The value of potentialOwner is not a valid owner: ${potentialOwner}`)
}