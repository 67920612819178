import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { FormItem, FormItemValue } from '../../entities/form-item';
import { MasterDataItem, MasterDataItemString } from '../../services/master-data.service';
import { isNullOrUndefined, phoneNumberRegExp } from '../util';
import { FormFieldTypes } from '../../enums/form-field-type';
import { FormSection } from '../../entities/form-section';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';


@Component({
  selector: 'form',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatSlideToggleModule
  ],
  templateUrl: './form.component.html',
  styleUrl: './form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormComponent implements OnInit {
  constructor(public changeDetectorRef: ChangeDetectorRef) {

  }

  @Input() formSections!: FormSection[];
  formFieldTypeEnum = FormFieldTypes;

  readonly shouldShowAsterisk = FormComponent.shouldShowAsterisk;
  private readonly defaultFormSectionIndex = 0;
  _phoneNumberRegExp = phoneNumberRegExp;

  ngOnInit(): void {
    this.formSections.forEach(x => {
      x.formItems.forEach(y => {
        if (isNullOrUndefined(y.sortCollection) || y.sortCollection) {
          y.collection?.sort(FormComponent.sort);
          y.stringCollection?.sort(FormComponent.sort);
        }
        y.formControl = new FormControl(y.value, y.validators);
        if (y.formFieldType === this.formFieldTypeEnum.Readonly.valueOf()) {
          y.formControl.disable();
        }

        if (y.commentBoxFormItem) {
          y.commentBoxFormItem.formControl = new FormControl(y.commentBoxFormItem.value, y.commentBoxFormItem.validators);
        }
        if (y.valueChangeCallback) {
          y.formControl.valueChanges.subscribe({
            next: (value) => { if (y.valueChangeCallback) y.valueChangeCallback(value); }
          })
        }

        return;
      });
    });
  }

  isValid(): boolean {
    const formItems = this.formSections.flatMap(x => x.formItems);

    if (formItems.some(x => !x.formControl?.valid && this.isVisible(x) && x.formFieldType !== this.formFieldTypeEnum.Readonly.valueOf())) return false;

    return true;
  }

  isVisible(formItem: FormItem): boolean {
    if (!formItem.visibilityFunction) return true;
    const conditionFormItem = this.formSections.flatMap(x => x.formItems).find(x => x.formDataType === formItem.visibilityFormItemDataTypeID);

    if (!conditionFormItem) return false;

    return formItem.visibilityFunction(String(conditionFormItem.formControl?.value));
  }

  private static shouldShowAsterisk(formItem: FormItem) {
    return formItem.showRequiredAsterisk === true || formItem.validators.some(x => x === Validators.required);
  }


  getFormItemValue(fieldId: number, formSectionSelector: number | string = this.defaultFormSectionIndex): FormItemValue {

    const formSection = typeof formSectionSelector === "number" ? this.formSections[formSectionSelector] : this.formSections.find(x => x.name === formSectionSelector);
    if (isNullOrUndefined(formSection)) {
      throw new Error(`Failed to locate FormSection with formSectionSelector: "${formSectionSelector}".`);
    }

    const formItem = formSection.formItems.find(x => x.formDataType === fieldId);

    if (typeof formItem === "undefined") {
      throw new Error(`Failed to locate FormItem with FieldId "${fieldId}".`);
    }

    if (isNullOrUndefined(formItem.formControl)) {
      throw new Error(`The FormControl for FormItem with FieldID "${fieldId}" is null or undefined. It should not be null or undefined.`);
    }

    return new FormItemValue(formItem);
  }

  private static sort(a: MasterDataItemString<string>, b: MasterDataItemString<string>): number
  private static sort(a: MasterDataItem, b: MasterDataItem): number
  private static sort(a: MasterDataItem | MasterDataItemString<string>, b: MasterDataItem | MasterDataItemString<string>): number {
    return a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase());
  }
}
