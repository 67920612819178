export enum FormFieldTypes {
    Text = 1,
    Date = 2,
    Dropdown = 3,
    Number = 4,
    Checkbox = 5,
    TextArea = 6,
    Toggle = 7,
    ToggleWithComment = 8,
    Readonly = 9,
    PhoneNumber = 10
}
