import { FormControl, ValidatorFn } from '@angular/forms';
import { MasterDataItem, MasterDataItemString } from '../services/master-data.service';
import { OwnerType, getOwnerFromString } from './owner-type';
import { isNullOrUndefined } from '../shared/util';

export interface FormItem {
  formFieldType: number;
  formDataType: number;
  value?: string | number | Date | boolean | null;
  formControl?: FormControl;
  collection?: MasterDataItem[];
  stringCollection?: MasterDataItemString<string>[];
  validators: ValidatorFn[];
  label: string;
  showRequiredAsterisk?: boolean;
  visibilityFormItemDataTypeID?: number;
  visibilityFunction?: (value: string) => boolean;
  maxDate?: Date;
  minDate?: Date;
  includeComment?: boolean;
  commentBoxFormItem?: FormItem;
  valueChangeCallback?: (value: unknown) => void;
  // Defaults to true.
  sortCollection?: boolean;
}


export class FormItemValue {
  constructor(formItem: FormItem) {
    this.formItem = formItem;
    this.value = formItem.formControl?.value;
  }

  readonly formItem: FormItem;
  readonly value: unknown;


  getString(): string {
    this.throwIfNull();
    return String(this.value);
  }

  getStringOptional(): ReturnType<typeof this.getString> | null {
    if (isNullOrUndefined(this.value)) {
      return null;
    }

    return this.getString();
  }

  getNumber(): number {
    this.throwIfNull();
    return Number(this.value);
  }

  getNumberOptional(): ReturnType<typeof this.getNumber> | null {
    if (isNullOrUndefined(this.value)) {
      return null;
    }

    return this.getNumber();
  }

  getBoolean(): boolean {
    this.throwIfNull();

    if (typeof this.value !== "boolean") {
      this.throwError(`value is not a valid boolean: ${String(this.value)}`);
    }

    return this.value;
  }

  getBooleanOptional(treatNullAsFalse: boolean): ReturnType<typeof this.getBoolean> | null {
    if (isNullOrUndefined(this.value)) {
      return treatNullAsFalse ? false : null;
    }

    return this.getBoolean();
  }

  getOwner(): OwnerType {
    this.throwIfNull();
    return getOwnerFromString(String(this.value));
  }

  private throwIfNull(): void | never {
    if (isNullOrUndefined(this.value)) {
      this.throwError("null/undefined is not a valid value.");
    }
  }

  private throwError(errorMessage: string): never {
    throw new Error(`The data for FormItem "${this.formItem.formDataType}" is invalid: ${errorMessage}`);
  }
}