@for (formSection of formSections; track formSection.name; let index = $index) {
@if (formSection.title) {
<div class="title">
    {{formSection.title}}
</div>
}
<div class="fields">
    @for (formItem of formSection.formItems; track formItem.formDataType) {
    @if (formItem.formFieldType === formFieldTypeEnum.Dropdown && isVisible(formItem)) {
    <div class="fieldContainer" [tabIndex]="index">
        <div class="fieldLabel">
            {{formItem.label}}
            @if (shouldShowAsterisk(formItem)) {
            <span class="requiredStar">*</span>
            }
        </div>
        @if (formItem.stringCollection){
        <mat-form-field appearance="outline" class="field">
            <mat-select [value]="formItem.value" [formControl]="formItem.formControl!">
                @for (item of formItem.stringCollection; track item) {
                <mat-option [value]="item.iD">{{item.name}}</mat-option>
                }
            </mat-select>
        </mat-form-field>
        } @else {
        <mat-form-field appearance="outline" class="field">
            <mat-select [value]="formItem.value" [formControl]="formItem.formControl!">
                @for (item of formItem.collection; track item.iD) {
                <mat-option [value]="item.iD">{{item.name}}</mat-option>
                }
            </mat-select>
        </mat-form-field>
        }
    </div>
    } @else if (formItem.formFieldType === formFieldTypeEnum.Text && isVisible(formItem)) {
    <div class="fieldContainer">
        <div class="fieldLabel">
            {{formItem.label}}
            @if (shouldShowAsterisk(formItem)) {
            <span class="requiredStar">*</span>
            }
        </div>
        <mat-form-field appearance="outline" class="field">
            <input matInput [value]="formItem.value" [name]="formItem.formDataType.toString()"
                [formControl]="formItem.formControl!">
        </mat-form-field>
    </div>
    } @else if (formItem.formFieldType === formFieldTypeEnum.Date && isVisible(formItem)) {
    <div class="fieldContainer">
        <div class="fieldLabel">
            {{formItem.label}}
            @if (shouldShowAsterisk(formItem)) {
            <span class="requiredStar">*</span>
            }
        </div>
        <mat-form-field appearance="outline" class="field">
            <input matInput [matDatepicker]="picker" [value]="formItem.value" [name]="formItem.formDataType.toString()"
                [formControl]="formItem.formControl!" [min]="formItem.minDate" [max]="formItem.maxDate">
            <mat-hint>DD/MM/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
    </div>
    } @else if (formItem.formFieldType === formFieldTypeEnum.Number && isVisible(formItem)) {
    <div class="fieldContainer">
        <div class="fieldLabel">
            {{formItem.label}}
            @if (shouldShowAsterisk(formItem)) {
            <span class="requiredStar">*</span>
            }
        </div>
        <mat-form-field appearance="outline" class="field">
            <input type="number" matInput [value]="formItem.value" [name]="formItem.formDataType.toString()"
                [formControl]="formItem.formControl!">
        </mat-form-field>
    </div>
    } @else if (formItem.formFieldType === formFieldTypeEnum.Checkbox && isVisible(formItem)) {
    <div class="fieldContainer">
        <div class="fieldLabel">
            {{formItem.label}}
            @if (shouldShowAsterisk(formItem)) {
            <span class="requiredStar">*</span>
            }
        </div>
        <mat-checkbox class="field" [checked]="formItem.value" [name]="formItem.formDataType.toString()"
            [formControl]="formItem.formControl!" (change)="changeDetectorRef.detectChanges()"></mat-checkbox>
    </div>
    } @else if (formItem.formFieldType === formFieldTypeEnum.TextArea && isVisible(formItem)) {
    <div class="fieldContainer">
        <div class="fieldLabel">
            {{formItem.label}}
            @if (shouldShowAsterisk(formItem)) {
            <span class="requiredStar">*</span>
            }
        </div>
        <mat-form-field appearance="outline" class="field">
            <textarea matInput [value]="formItem.value" [name]="formItem.formDataType.toString()"
                [formControl]="formItem.formControl!"></textarea>
        </mat-form-field>
    </div>
    } @else if (formItem.formFieldType === formFieldTypeEnum.Toggle && isVisible(formItem)) {
    <div class="toggleContainer">
        <div class="toggleLabel">
            {{formItem.label}}
            @if (shouldShowAsterisk(formItem)) {
            <span class="requiredStar">*</span>
            }
        </div>
        <div class="toggleContentContainer">
            <div class="toggle">
                <mat-slide-toggle [hideIcon]="true" [formControl]="formItem.formControl!"></mat-slide-toggle>
            </div>
        </div>
    </div>
    } @else if (formItem.formFieldType === formFieldTypeEnum.ToggleWithComment && isVisible(formItem)) {
    <div class="toggleWithCommentContainer">
        <div class="toggleContainer">
            <div class="toggleLabel">
                {{formItem.label}}
                @if (shouldShowAsterisk(formItem)) {
                <span class="requiredStar">*</span>
                }
            </div>
            <div class="toggleContentContainer">
                <div class="toggle">
                    <mat-slide-toggle [hideIcon]="true" [formControl]="formItem.formControl!"></mat-slide-toggle>
                </div>
            </div>
        </div>

        @if (formItem.includeComment && formItem.commentBoxFormItem && isVisible(formItem.commentBoxFormItem)) {
        <div class="fieldContainer">
            <div class="fieldLabel">
                {{formItem.commentBoxFormItem.label}}
                @if (shouldShowAsterisk(formItem.commentBoxFormItem)) {
                <span class="requiredStar">*</span>
                }
            </div>
            <mat-form-field appearance="outline" class="field">
                <textarea matInput [value]="formItem.commentBoxFormItem.value"
                    [name]="formItem.commentBoxFormItem.formDataType.toString()"
                    [formControl]="formItem.commentBoxFormItem.formControl!"></textarea>
            </mat-form-field>
        </div>
        }
    </div>
    } @else if (formItem.formFieldType === formFieldTypeEnum.Readonly && isVisible(formItem)) {
    <div class="fieldContainer">
        <div class="fieldLabel">
            {{formItem.label}}
        </div>
        <mat-form-field appearance="outline" class="field">
            <input matInput [value]="formItem.value" [name]="formItem.formDataType.toString()"
                [formControl]="formItem.formControl!">
        </mat-form-field>
    </div>
    } @else if (formItem.formFieldType === formFieldTypeEnum.PhoneNumber && isVisible(formItem)) {
    <div class="fieldContainer">
        <div class="fieldLabel">
            {{formItem.label}}
            @if (shouldShowAsterisk(formItem)) {
            <span class="requiredStar">*</span>
            }
        </div>
        <mat-form-field appearance="outline" class="field">
            <input matInput [value]="formItem.value" [name]="formItem.formDataType.toString()"
                [formControl]="formItem.formControl!" type="tel" [pattern]="_phoneNumberRegExp">
        </mat-form-field>
    </div>
    }
    }
</div>
}